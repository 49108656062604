<template>
  <el-card>
    <el-row :gutter="20" class="header">
      <el-col :span="7">
        <el-input placeholder="请输入医生姓名..." clearable v-model="queryForm.query"></el-input>
      </el-col>
      <el-button type="primary" :icon="Search" @click="initBigTypeList">搜索</el-button>
      <el-button type="primary" @click="handleDialogValue()">添加中医院医生</el-button>
    </el-row>
    <el-table :data="tableData" height="550" stripe style="width: 100%">
      <el-table-column prop="doctorId" label="编号" />
      <el-table-column prop="doctorName" label="姓名" />
      <el-table-column prop="doctorPhone" label="手机号" />
      <el-table-column prop="departmentName" label="科室" />
      <el-table-column prop="userLevel" label="权限" />
<!--      <el-table-column prop="remark" label="商品大类描述"/>-->


<!--      <el-table-column  prop="image" label="商品大类图片" width="200">-->
<!--        <template v-slot="scope">-->
<!--          <img :src="getServerUrl()+'/image/bigType/'+scope.row.image" width="80" height="80"/>-->
<!--        </template>-->
<!--      </el-table-column>-->

      <el-table-column prop="action" label="操作" width="400">
        <template v-slot="scope">
<!--          <el-button type="success" @click="handleChangeImage(scope.row)">更换图片</el-button>-->
          <el-button type="primary" :icon="Edit" @click="handleDialogValue(scope.row)">编辑</el-button>
          <el-button type="danger" :icon="Delete" @click="handleDelete(scope.row)">删除</el-button>
          <el-button type="success" :icon="Edit" @click="Change_password(scope.row)">修改密码</el-button>
        </template>
      </el-table-column>

    </el-table>

    <el-pagination
      v-model:currentPage="queryForm.pageNum"
      :page-sizes="[10, 20, 30, 40,50]"
      :page-size="queryForm.pageSize"
      :small="small"
      :disabled="disabled"
      :background="background"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    >
    </el-pagination>
  </el-card>
  <Dialog v-model="dialogVisible" :dialogTitle="dialogTitle" @initBigTypeList="initBigTypeList" :id="id" :dialogValue="dialogValue"/>
  <ImageDialog v-model="imageDialogVisible" :imageDialogValue="imageDialogValue" @initBigTypeList="initBigTypeList"/>

  <el-dialog
    title="修改密码"
    v-model:visible="password_dialog_Visible"
    :model-value="password_dialog_Visible"
    width="25%"

    @close="password_dialog_Visible = false">
    <el-row :gutter="20">
      <el-col :span="5">
        <span>原密码:</span>
      </el-col>
      <el-col :span="12">
        <el-input disabled v-model="password" type="password" placeholder="原密码" width=10%></el-input>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="5">
        <span>新密码:</span>
      </el-col>
      <el-col :span="12">
        <el-input v-model="newPassword" placeholder="请输入新密码"></el-input>
      </el-col>
    </el-row>

    <!--    <span style="display: flex">-->
    <!--      <span>原密码</span>-->
    <!--      <el-input disabled v-model="password" placeholder="原密码" width=10%></el-input>-->
    <!--    </span>-->
    <!--    <span>新密码-->
    <!--      <el-input v-model="newPassword" placeholder="请输入新密码"></el-input>-->
    <!--    </span>-->
    <template #footer>
      <span  class="dialog-footer" >
          <el-button @click="password_dialog_Visible = false">取 消</el-button>
          <el-button type="primary" @click="report_newPassword">确 定</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import {Search,Edit,Delete } from '@element-plus/icons-vue'
import { ref } from 'vue'
import axios, { del } from "@/util/axios";
import {getServerUrl} from "@/config/sys";
import Dialog from './components/dialog'
import ImageDialog from './components/imageDialog'
import {ElMessageBox,ElMessage} from 'element-plus'

const queryForm=ref({
  query:'',
  pageNum:1,
  pageSize:10
})

const total=ref(0)

const id=ref(-1)

const tableData=ref([
])

  const dialogValue=ref({})

const imageDialogValue=ref({})

const dialogTitle=ref('')

const initBigTypeList=async()=>{
  const res=await axios.post("zy-user/list",queryForm.value);
  console.log(res)
  tableData.value=res.data.data.map.zyUserList;
  total.value=res.data.data.map.total;

  // console.log('xxx')
  // const res=await axios.post("admin/bigType/list",queryForm.value);
  // tableData.value=res.data.bigTypeList;
  // total.value=res.data.total;
}

initBigTypeList();

const dialogVisible=ref(false)

const imageDialogVisible=ref(false)


const handleSizeChange=(pageSize)=>{
  queryForm.value.pageNum=1;
  queryForm.value.pageSize=pageSize;
  initBigTypeList();
}

const handleCurrentChange=(pageNum)=>{
  queryForm.value.pageNum=pageNum;
  initBigTypeList();
}


const handleDialogValue = (row) => {

  if(row){
    dialogValue.value=JSON.parse(JSON.stringify(row));
    console.log(row)
    console.log('hhh'+row.doctorId)
    id.value=row.doctorId;
    // id.value=1;
    // id.value=row.id;
    console.log('kkk'+id.value)

    dialogTitle.value="修改医生信息"
  }else{
    id.value=-1;
    console.log(id)
    dialogTitle.value="添加医生"
  }
  dialogVisible.value=true;
}

const handleDelete = (row) => {

  ElMessageBox.confirm(
    '您确定要删除这条记录吗?',
    '系统提示',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  )
    .then(async() => {

      console.log("doctorId="+row.doctorId)
      console.log(row)
      let res=await axios.del("zy-user/removeById?doctorId="+row.doctorId);
      console.log(res)
      if(res.data.code==0){
        ElMessage({
          type: 'success',
          message: '删除成功！',
        });
        initBigTypeList();
      }else{
        ElMessage({
          type: 'error',
          message: res.data.msg,
        });
      }

    })
    .catch(() => {

    })
}

const password=ref()
const newPassword=ref()
const userName=ref()
const password_message=ref({
  password:'',
  newPassword:'',
  userName:''
})
const password_dialog_Visible=ref(false)

// 点击修改密码 弹出对话框
const Change_password = async(row) => {
  password_dialog_Visible.value=true
  console.log("打印该行")
  console.log(row)
  console.log(row.userId)
  const res=await axios.get("user/getUser?userId="+row.userId);
  console.log(res)
  password.value=res.data.data.user.password
  userName.value=res.data.data.user.userName
  password_message.value.password=res.data.data.user.password
  password_message.value.userName=res.data.data.user.userName
  console.log(password_message.value.password)
  console.log(password_message.value.userName)
}

// 点击确定 提交新密码
const report_newPassword = async(row) => {
  console.log("打印新密码")
  console.log(newPassword)
  if( newPassword.value == null ){
    ElMessage({
      type: 'warning',
      message: '新密码不可为空！',
    });
    return
  }
  else if( newPassword.value.length <= 5){
    ElMessage({
      type: 'warning',
      message: '新密码不可低于6位！',
    });
    return
  }
  password_message.value.newPassword=newPassword.value
  console.log(password_message.value.newPassword)

  // const res=await axios.post("user/password?userName="+userName.value+"&password="+password.value+"&newPassword="+newPassword.value);
  const res=await axios.post("user/password",password_message.value);
  console.log(res)
  if( res.data.code == 0 ){
    ElMessage({
      type: 'success',
      message: '修改成功！',
    });
    password_dialog_Visible.value=false

  }

}


// 更换图片处理
const handleChangeImage = (row) => {
  imageDialogValue.value=JSON.parse(JSON.stringify(row));
  imageDialogVisible.value=true;
}

</script>

<style lang="scss" scoped>


.header{
  padding-bottom: 16px;
  box-sizing: border-box;
}

.el-pagination{
  padding-top: 15px;
  box-sizing: border-box;
}

</style>