<template>
  <el-dialog
    :model-value="dialogVisible"
    :title="dialogTitle"
    width="30%"
    @close="handleClose"
  >
    <el-form ref="formRef" :model="form" label-width="100px" :rules="rules">
      <el-form-item label="姓名" prop="doctorName">
        <el-input v-model="form.doctorName"></el-input>
      </el-form-item>
      <el-form-item label="手机号" prop="doctorPhone">
        <el-input v-model="form.doctorPhone"></el-input>
      </el-form-item>
<!--      <el-form-item label="科室1" prop="departmentName">-->
<!--        <el-input v-model="form.departmentName"></el-input>-->
<!--      </el-form-item>-->
      <el-form-item label="科室" prop="departmentName">
        <el-select v-model="form.departmentName" placeholder="请选择科室" @focus="getDepData" style="width:100%">
          <el-option
            v-for="item in dep"
            :key="item.departId"
            :label="item.departName"
            :value="item.departName">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="权限" prop="userLevel">
        <el-input v-model="form.userLevel"></el-input>
      </el-form-item>
<!--      <el-form-item label="医生描述" prop="remark">-->
<!--        <el-input-->
<!--          v-model="form.remark"-->
<!--          :rows="4"-->
<!--          type="textarea"-->
<!--        />-->
<!--      </el-form-item>-->
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="handleClose">取消</el-button>
        <el-button type="primary" @click="handleConfirm">确认</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits,ref ,defineProps,watch} from 'vue'
import axios from "@/util/axios";
import { ElMessage } from "element-plus";

const dep = ref([])
const range_department=ref([
  '选择预约挂号科室','急诊科','重症医学科','外一科（普外、肛肠等方向）','外二科（泌外、男科等方向）','骨伤一科（脊柱、脑外、创伤等方向）','骨伤二科（关节、胸外、创伤等方向）','妇产科','眼耳鼻喉科','手术室','口腔科','内一科（心病方向）','内二科（肝病、脾胃、肿瘤等方向）','内三科（脑病方向）','内五科（肺病、老年病等方向）','内六科（肾病、内分泌、风湿等方向）','针灸康复科','儿科','治未病科','门诊','体检科','药剂科','医学检验科','功能科','内镜室','高压氧','放射科','CT室','供应室','120中心','新院导诊','轮科'
])

const props=defineProps({
  dialogTitle:{
    type:String,
    default:'',
    required:true
  },
  id:{
    type:Number,
    // 默认值
    default:-1,
    required:true
  },
  dialogValue:{
    type:Object,
    default:()=>{}
  }
})

const initFormData=async(id)=>{
  const res=await axios.get("zy-user/contacts/"+id);
  console.log('ksdadsadad')
  console.log(res.data.zyUser.doctorName)
  console.log(res)
  form.value=res.data.zyUser;
}

const getDepData = () => {
  axios.get("zy-department/findAll")
    .then((res)=>{
      dep.value=res.data.data.zyDepartmentList.message
      // console.log(dep.value)
    });
};

const form=ref({
  id:-1,
  doctorId:'',
  doctorName:'',
  doctorPhone:'',
  departmentName:'',
  departmentId:'',
  userLevel:'',
  // remark:''
})

watch(
  ()=>props.dialogValue,
  ()=>{
    console.log("dialogValue12123="+props.dialogValue);
    form.value=props.dialogValue;
  },
  {deep:true,immediate:true}

  // ()=>props.id,
  // ()=>{
  //
  //   console.log("dialogTitle="+props.dialogTitle);
  //   console.log("id="+props.id);
  //   let id=props.id
  //
  //   form.value.id=id;
  //
  //   if(id!=-1){
  //     // 请求后端 获取数据
  //     initFormData(id)
  //   }
  // },
  // {deep:true,immediate:true}
)


const emits=defineEmits(['update:modelValue','initBigTypeList'])

const formRef=ref(null)

const handleClose = () => {
  // console.log("测试关闭对话框")
  // console.log(form.value)
  emits('update:modelValue',false)
  formRef.value.resetFields();
  // console.log(form.value)
  // this.$nextTick(()=>{
  //   this.$refs.formRef.resetFields();
  // })
  //
  // console.log(form.value)
}


const rules=ref({
  name:[
    {
      required: true,
      message: '请输入商品大类名称！',
    }
  ],
  remark:[
    {
      required: true,
      message: '请输入商品大类描述！',
    },
  ]
})

const handleConfirm = () => {
  formRef.value.validate(async (valid) => {
    let depId = -1;
    for (let i = 0; i < dep.value.length; i++) {
      if (dep.value[i].departName == form.value.departmentName) {
        depId = dep.value[i].departId;
        break;
      }
    }
    // console.log("depId=" + depId);
    form.value.departmentId= depId;

    //添加医生
    if (valid && props.id == -1) {
      try{
        let result=await axios.post("zy-user/addZyUser",form.value);
        let data=result.data;
        if(data.code==0){
          ElMessage.success("执行成功");
          formRef.value.resetFields();
          emits("initBigTypeList")
          handleClose();
        }else{
          ElMessage.error(data.msg);
        }
      }catch(err){
        console.log("error:"+err)
        ElMessage.error('系统运行出错，请联系管理员');
      }
    }
    // 修改医生信息
    else if (valid && props.id != -1) {
      try{
        console.log("xiugai1:"+form.value.doctorId)

        let result=await axios.post("zy-user/update",form.value);
        console.log("xiugai2:"+result)
        let data=result.data;
        if(data.code==0){
          ElMessage.success("执行成功");
          formRef.value.resetFields();
          emits("initBigTypeList")
          handleClose();
        }else{
          ElMessage.error(data.msg);
        }
      }catch(err){
        console.log("error:"+err)
        ElMessage.error('系统运行出错，请联系管理员');
      }
    }
    else {
      return false
    }
  })
}
</script>

<style scoped>

</style>